import React from 'react';
import { createUseStyles } from 'react-jss';
import GoogleButton from 'react-google-button';

import firebase from 'firebase/app';
import 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth';

const useStyles = createUseStyles({
  button: {
    margin: '10px 0',
    border: '0 solid',
    boxShadow: 'none !important',
    borderRadius: '0px 10px 10px 0px!important',
    backgroundColor: '#e4e4e4',
    fontFamily: `'Exo 2', sans-serif`,
  },
});

firebase.initializeApp({
  apiKey: 'AIzaSyAcbqnOlal1SDrjhGnSqWbxX9uspZWKtqw',
  authDomain: 'chillmate-56f15.firebaseapp.com',
  projectId: 'chillmate-56f15',
  storageBucket: 'chillmate-56f15.appspot.com',
  messagingSenderId: '1092243650549',
  appId: '1:1092243650549:web:46267696cb8d0eabe5d2bc',
});

const auth = firebase.auth();

const SignIn = () => {
  const classes = useStyles();

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  return <GoogleButton className={classes.button} onClick={signInWithGoogle} />;
};

const SignOut = () => {
  const classes = useStyles();

  return auth.currentUser && (
    <button className={classes.button} onClick={() => auth.signOut()}>Sign out</button>
  );
};

function Login() {
  const [user] = useAuthState(auth);

  return user ? <SignOut /> : <SignIn />;
}

export default Login;
