import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    gap: '16px',
  },
  link: {
    fontSize: '12px',
    color: ({ theme }) => theme.colorLinkPrimary,
    textDecoration: 'none',
    '&:hover': {
      color: ({ theme }) => theme.colorLinkPrimaryHover,
    },
  },
});

function Legal({ style = {} }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={`${classes.container} legal-container`} style={style}>
      <a href="/privacy" className={classes.link}>Privacy Policy</a>
      <a href="/cookies" className={classes.link}>Cookie Policy</a>
      <a href="/terms" className={classes.link}>Terms of Service</a>
    </div>
  );
}

export default Legal;
