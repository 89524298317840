import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import Panel from 'components/common/Panel';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: '12px',
  },
  title: {
    textAlign: 'center',
  },
  heading: {
    fontWeight: 700,
    margin: 0,
    textTransform: 'uppercase',
  },
  section: {
    marginBottom: '1rem',
  },
  subsection: {
    margin: '.5rem 0',
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
});

function Privacy() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.container}>
      <Panel width="600px" alignItems="flex-start">
        <h3 className={classes.title}>PRIVACY POLICY</h3>
        <div className={classes.section}>Your privacy is important to us, so Konstantinos-Marinos Varsamis, a service provider
          based in the GR, has created the following Privacy Policy (&quot;Policy&quot;) to let you know what information we
          collect when you visit our Site https://chillmate.app (&quot;Site&quot;), why we collect it and how we use it.</div>
        <div className={classes.section}>
          <div className={classes.subsection}>The terms &quot;You,&quot; &quot;Your,&quot; &quot;Yours&quot; and &quot;User&quot;
            refer to the entity/person/organization using our Site.</div>
          <div className={classes.subsection}>When this Policy mentions &quot;We&quot;, &quot;Us,&quot; and &quot;Our&quot; it refers
            to Konstantinos-Marinos Varsamis and its subsidiaries and affiliates.</div>
        </div>
        <div className={classes.section}>This Privacy Policy is governed by our <a
          href="/terms" target="_blank">Terms of Services</a>.</div>
        <div className={classes.section}>For any questions regarding this Policy or any requests regarding the processing of
          personal data, please contact us at privacy@chillmate.app.</div>
        <div className={classes.section}>
          <h3 className={classes.heading}>1. INFORMATION WE COLLECT FROM YOU</h3>
          <div className={classes.subsection}>We collect the information You provide to us and this information is necessary for the
            adequate performance of the contractual arrangement which is in place between You and us and allow us to comply
            with our legal obligations.</div>
          <ul>
            <li>Account Signup Information. When You create the account, we ask You to provide the signup information, such as
              Email, Username, Name, Surname.</li>
            <li>Login information. We collect Login information if You are logging to our account with Authentication Data.
            </li>
          </ul>
        </div>
        <div className={classes.section}>
          <h3 className={classes.heading}>2. INFORMATION WE COLLECT AUTOMATICALLY</h3>
          <div className={classes.subsection}>When you use our Site or contact us directly we may collect information, including your
            personal information, about the way you act in our Site, the services You use and how You use them.</div>
          <div className={classes.subsection}>This information is necessary for the adequate performance of the contract between You
            and us, to enable us to comply with legal obligations and given our legitimate interest in being able to provide
            and improve the functionalities of the Site.</div>
          <ul>
            <li>Log data and Device information. We automatically collect log data and device information when you access and
              use the Site, even if you have not created an Account or logged in. That information includes, among other
              things: Internet protocol (IP) addresses, Browser type, Operating system, Date/time stamp.</li>
            <li>Tracking technologies and Cookies. We use Cookies, Tags, Beacons. We also automatically collect information
              about device’s operating system, .</li>
            <li>Publicly available personal Information.</li>
            <li>Usage information with Plausible Analytics</li>
          </ul>
        </div>
        <div className={classes.section}>
          <h3 className={classes.heading}>3. THE WAY WE USE YOUR INFORMATION</h3>
          <div className={classes.subsection}>We process your information adhering to the general data processing principles.</div>
          <div className={classes.subsection}>We may use the information we collect through our Site for a number of reasons,
            including to:</div>
          <ul>
            <li>to identify user</li>
            <li>to stay connected</li>
            <li>to contact user</li>
            <li>to improve services</li>
            <li>to customize marketing</li>
            <li>to create statistics and analyze market</li>
          </ul>
          <div className={classes.subsection}>We will normally collect personal information from you only where we have your consent
            to do so, where we need the personal information to perform a contract with you, or where the processing is in our
            legitimate business interests.</div>
        </div>
        <div className={classes.section}>
          <h3 className={classes.heading}>4. HOW WE MAY SHARE YOUR INFORMATION</h3>
          <div className={classes.subsection}>We may share your information with third parties that we use to provide and support our
            Services:</div>
          <ul>
            <li>Social media and advertising</li>
          </ul>
          <div className={classes.subsection}>We may also disclose your information to third parties:</div>
          <ul>
            <li>where required by law or regulatory requirement, court order or other judicial authorization;</li>
            <li>in response to lawful requests by public authorities, including for the purposes of meeting national security
              and law enforcement requirements;</li>
            <li>in connection with the sale, transfer, merger, bankruptcy, restructuring or other reorganization of a
              business;</li>
            <li>to protect or defend our rights, interests or property, or that of third parties; (e) to investigate any
              wrongdoing in connection with our products and services;</li>
            <li>and to protect the vital interests of an individual.</li>
          </ul>
        </div>
        <div className={classes.section}>
          <h3 className={classes.heading}>5. COOKIES</h3>
          <div className={classes.subsection}>Cookies are small text files stored by your browser on your computer when you visit our
            Site. We use cookies to improve our Site and make it easier to use. Cookies permit us to recognize users and avoid
            repetitive requests for the same information.</div>
          <div className={classes.subsection}>Please check our <a href="/cookies"
                                                                  target="_blank">Cookie Policy</a> to find more information about cookies we use.</div>
          <div className={classes.subsection}>You may find more information about how to delete cookies, as well as the other useful
            information related to the use of the cookies, on the website http://www.allaboutcookies.org/.</div>
        </div>
        <div className={classes.section}>
          <h3 className={classes.heading}>6. INFORMATION FROM MINORS</h3>
          <div className={classes.subsection}>This site and our Services are not intended for or directed to persons under the age of
            18. We do not knowingly collect or ask for information from minors. We do not knowingly allow minors to use our
            Site or Services.</div>
          <div className={classes.subsection}>We will delete any information we discover is collected from a minor. Please contact us
            using the contact details below if you believe we might have information about a minor.</div>
        </div>
        <div className={classes.section}>
          <h3 className={classes.heading}>7. SENSITIVE INFORMATION</h3>
          <div className={classes.subsection}>We do not collect sensitive information such as political opinions, religious or
            philosophical beliefs, racial or ethnic origin, genetic data, biometric data, health data or data related a sexual
            orientation.</div>
          <div className={classes.subsection}>Please do not send, upload, or provide us any sensitive data and contact us using the
            contact details below if you believe that we might have such information. We have a right to delete any
            information we believe it might contain sensitive data.</div>
        </div>
        <div className={classes.section}>
          <h3 className={classes.heading}>8. THIRD PARTY LINKS</h3>
          <div className={classes.subsection}>Our Site may have links to other websites. Please review their privacy policies to
            learn more about how they collect and use your personal data, because we do not control their policies and
            personal data processing practices.</div>
        </div>
        <div className={classes.section}>
          <h3 className={classes.heading}>9. RETENTION</h3>
          <div className={classes.subsection}>We retain your personal information to provide services to you and as otherwise
            necessary to comply with our legal obligation, resolve disputes, and enforce our agreements.</div>
          <div className={classes.subsection}>We will retain your personal information as long as we need it to provide services to
            you, unless we are otherwise required by law or regulations to retain your personal information longer.</div>
        </div>
        <div className={classes.section}>
          <h3 className={classes.heading}>10. SECURITY</h3>
          <div className={classes.subsection}>We have implemented security measures designed to protect the personal information you
            share with us, including physical, electronic and procedural measures. Among other things, we regularly monitor
            our systems for possible vulnerabilities and attacks.</div>
          <div className={classes.subsection}>Regardless of the measures and efforts taken by us, the transmission of information via
            internet, email or text message is not completely secure. We do not guarantee the absolute protection and security
            of your personal information.</div>
          <div className={classes.subsection}>We therefore encourage you to avoid providing us or anyone with any sensitive
            information of which you believe its disclosure could cause you substantial or irreparable harm.</div>
          <div className={classes.subsection}>If you have any questions regarding the security of our Site or Services, you are
            welcome to contact us at privacy@chillmate.app.</div>
        </div>
        <div className={classes.section}>
          <h3 className={classes.heading}>11. YOUR RIGHTS</h3>
          <div className={classes.subsection}>You are entitled to a range of rights regarding the protection of your personal
            information. Those rights are:</div>
          <ul>
            <li>The right to access the information we have about you. If you wish to access your personal information that we
              collect, you can do so at any time by contacting us using the contact details provided below.</li>
            <li>The right to rectify inaccurate information about you. You can correct, update or request deletion of your
              personal information by contacting us using the contact details provided below.</li>
            <li>The right to object the processing. When we rely on your consent to process your personal information, you may
              withdraw consent at any time by contacting us using the contact details provided below. This will not affect the
              lawfulness of processing prior to the withdrawal of your consent.</li>
            <li>The right to lodge a complaint. You can raise questions or complaints to the national Data Protection Agency
              in your country of residence in the event where your rights may have been infringed. However, we recommend
              attempting to reach a peaceful resolution of the possible dispute by contacting us first.</li>
            <li>The right to erase any data concerning you. You may demand erasure of data without undue delay for legitimate
              reasons, e.g. where data is no longer necessary for the purposes it was collected, or where the data has been
              unlawfully processed.</li>
          </ul>
        </div>
        <div className={classes.section}>
          <h3 className={classes.heading}>12. APPLICATION OF POLICY</h3>
          <div className={classes.subsection}>This Policy was created with the help of the <a
            className={classes.link}
            href="https://termshub.io/privacy-policy?utm_source=referral&amp;utm_medium=generated_documents&amp;utm_campaign=referral_documents&amp;utm_content=pp_th_text"
            target="_blank">Privacy Policy Generator from TermsHub</a> and applies only to the services offered by our
            Company. Our Policy does not apply to services offered by other companies or individuals, including products or
            sites that may be displayed to you in search results, sites that may include our services or other sites linked
            from our Site or Services.</div>
        </div>
        <div className={classes.section}>
          <h3 className={classes.heading}>13. AMENDMENTS</h3>
          <div className={classes.subsection}>Our Policy may change from time to time. We will post any Policy changes on our Site
            and, if the changes are significant, we may consider providing a more explicit notice (including, for certain
            services, email notification of Policy changes).</div>
        </div>
        <div className={classes.section}>
          <h3 className={classes.heading}>14. ACCEPTANCE OF THIS POLICY</h3>
          <div className={classes.subsection}>We assume that all Users of this Site have carefully read this document and agree to
            its contents. If someone does not agree with this Policy, they should refrain from using our Site. We reserve the
            right to change our Policy at any time and inform by using the way as indicated in Section 13. Continued use of
            this Site implies acceptance of the revised Policy.</div>
        </div>
        <div className={classes.section}>
          <h3 className={classes.heading}>15. FURTHER INFORMATION</h3>
          <div className={classes.subsection}>If you have any further questions regarding the data we collect, or how we use it, then
            please feel free to contact us at the details as indicated above.</div>
          <div className={classes.subsection}>Users from EU and EEA can easily contact our European representative:
            Konstantinos-Marinos Varsamis privacy@chillmate.app</div>
        </div>
      </Panel>
    </div>
  );
}

export default Privacy;
