import { createStore } from 'easy-peasy';

import user from './models/user';
import data from './models/data';
import chat from './models/chat';

const storeModel = {
  user,
  data,
  chat,
};

export default createStore(storeModel);
