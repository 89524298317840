import React, { useEffect, useRef } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useStoreState } from 'easy-peasy';
import { useSocket } from 'use-socketio';

import WhiteboardClass from 'common/Whiteboard';

const useStyles = createUseStyles({
  container: {
    width: '300px',
    height: '100%',
    position: 'relative',
  },
  canvas: {
    width: '100%',
    height: '100%',
  },
});

function Whiteboard() {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const canvasRef = useRef();
  const whiteboardRef = useRef();

  const { socket } = useSocket();

  const profile = useStoreState((state) => state.user);

  useEffect(() => {
    console.log(socket, canvasRef.current);
    if (socket && canvasRef.current) {
      const whiteboard = new WhiteboardClass(
        canvasRef.current,
        socket,
        `hsl(${profile.color}, 70%, 50%)`,
      );

      console.log('whiteboard', whiteboard);
      whiteboardRef.current = whiteboard;
    }
  }, [socket, canvasRef]);

  return (
    <div className={classes.container}>
      <canvas ref={canvasRef} className={classes.canvas} />
    </div>
  );
}

export default Whiteboard;
