import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  panel: {
    backgroundColor: '#fff',
    borderRadius: '24px',
    boxShadow: ({ theme }) => theme.shadowPrimary,

    width: (props) => props.width || '100%',
    height: (props) => props.height || 'auto',
    boxSizing: 'border-box',

    padding: '24px',
    margin: '24px',

    display: 'flex',
    alignItems: (props) => props.alignItems || 'center',
    justifyContent: (props) => props.justifyContent || 'center',
    flexDirection: (props) => props.flexDirection || 'column',
    overflow: (props) => props.overflow || 'initial',

    position: 'relative',
    transition: 'width 200ms ease-in',
  },
});

function Panel(props) {
  const theme = useTheme();
  const classes = useStyles({ ...props, theme });

  return (
    <div className={classes.panel}>
      {props.children}
    </div>
  );
}

export default Panel;
