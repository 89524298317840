import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import { ReactComponent as DisabledIcon } from 'images/icons/disabled.svg';

const useStyles = createUseStyles({
  button: {
    width: '20%',
    height: '20%',
    boxShadow: ({ theme, enabled }) => (
      enabled
      ? `0 5px 14px -6px ${theme.colorPrimaryPale}`
      : `0 5px 14px -6px ${theme.colorDangerPale}`
    ),
    borderRadius: '100%',
    cursor: 'pointer',
  },
  iconContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',
    borderRadius: '100%',
    backgroundColor: ({ theme, enabled }) => enabled ? theme.colorPrimary : theme.colorDanger,
  },
  sliderContainer: {
    width: '100%',
    height: ({ expanded }) => expanded ? '350%' : '50%',
    position: 'absolute',
    bottom: '50%',
    left: 0,
    borderRadius: '9999px 9999px 0 0',
    transition: 'height 200ms ease-in',
    backgroundColor: ({ theme }) => theme.colorSecondary,
    overflow: 'hidden',
  },
  sliderBar: {
    width: '100%',
    backgroundColor: ({ theme }) => theme.colorPrimary,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  disabled: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

function ControlButton({
  icon,
  className,
  onSlide = null,
  onToggle = null,
  onClick = null,
  onSliderShow = null,
  onSliderHide = null,
  initialStatus = true,
}, ref) {
  const [enabled, setEnabled] = useState(initialStatus || !onToggle);
  const [expanded, setExpanded] = useState(false);

  const [isPressed, setPressed] = useState(false);
  const [percentage, setPercentage] = useState(50);
  const [mouseMoves, setMouseMoves] = useState(0);

  const sliderRef = useRef();

  const theme = useTheme();
  const classes = useStyles({ enabled, expanded, theme });

  useImperativeHandle(ref, () => ({
    setPercentage,
  }));

  useEffect(() => {
    if (onSlide) {
      onSlide(percentage);
    }
  }, [percentage]);

  useEffect(() => {
    if (expanded && onSliderShow) {
      onSliderShow();
    } else if (!expanded && onSliderHide) {
      onSliderHide();
    }
  }, [expanded]);

  const toggle = () => {
    const newStatus = !enabled;
    setEnabled(newStatus);

    if (onToggle === null) {
      return;
    }

    onToggle(newStatus);
  };

  const getMouseY = (event) => event.clientY - event.currentTarget.getBoundingClientRect().top;

  const getSliderPercentage = (event) => {
    const sliderHeight = sliderRef.current.clientHeight;
    const buttonHalfHeight = event.currentTarget.clientHeight / 2;

    let mouseY = getMouseY(event);
    mouseY = mouseY > buttonHalfHeight ? buttonHalfHeight : mouseY;

    const y = Math.abs(mouseY - buttonHalfHeight);

    let percentage = (100 / sliderHeight) * y;
    percentage = percentage < 0 ? 0 : percentage; 
    percentage = percentage > 100 ? 100 : percentage;

    return percentage;
  };

  const handeMouseDown = (event) => {
    setPressed(true);
    setMouseMoves(0);

    if ((!onToggle && !onClick) || getMouseY(event) < 0) {
      setPercentage(getSliderPercentage(event));
    }
  };

  const handleMouseMove = (event) => {
    if (isPressed && ((!onToggle && !onClick) || getMouseY(event) < 0 || mouseMoves >= 10)) {
      setPercentage(getSliderPercentage(event));
      setMouseMoves((prev) => prev + 1);
    }
  };

  const handleMouseUp = (event) => {    
    setPressed(false);

    if ((onToggle || onClick) && mouseMoves < 10 && getMouseY(event) >= 0) {
      if (onToggle) {
        toggle();
      }
      if (onClick) {
        onClick();
      }
    }
  };

  const handleMouseEnter = () => {
    setExpanded(true);
  };

  const handleMouseLeave = () => {
    setPressed(false);
    setExpanded(false);
  };

  const handleClick = () => {
    if (!onSlide) {
      if (onToggle) {
        toggle();
      }
      if (onClick) {
        onClick();
      }
    }
  };

  return (
    <div
      className={`${classes.button} ${className}`}
      onMouseEnter={onSlide ? handleMouseEnter : () => {}}
      onMouseLeave={onSlide ? handleMouseLeave : () => {}}
      onMouseDown={onSlide ? handeMouseDown : () => {}}
      onMouseMove={onSlide ? handleMouseMove : () => {}}
      onMouseUp={onSlide ? handleMouseUp : () => {}}
      onClick={handleClick}
    >
      {onSlide ? (
        <div
          className={classes.sliderContainer}
          ref={sliderRef}
        >
          <div
            className={classes.sliderBar}
            style={{
              height: `${percentage.toFixed(2)}%`,
            }}
          ></div>
        </div>
      ) : null}
      <div className={classes.iconContainer}>
        {icon}
        {onToggle && !enabled ? (
          <DisabledIcon className={classes.disabled} />
        ) : null}
      </div>
    </div>
  );
}

export default forwardRef(ControlButton);
