import SimplePeer from 'simple-peer';

class Peer extends SimplePeer {
  BUFFER_FULL_THRESHOLD = 65536; // 64 kB

  constructor(opts) {
    super(opts);

    this.webRTCPaused = false;
    this.webRTCMessageQueue = [];
  }

  sendMessageQueued() {
    // Sending message queued

    this.webRTCPaused = false;

    let message = this.webRTCMessageQueue.shift();

    while (message) {
      if (this._channel.bufferedAmount && this._channel.bufferedAmount > this.BUFFER_FULL_THRESHOLD) {
        // WebRTC buffer full, pause sending
        this.webRTCPaused = true;
        this.webRTCMessageQueue.unshift(message);

        const listener = () => {
          // WebRTC buffer drained, resume sending
          this._channel.removeEventListener('bufferedamountlow', listener);
          this.sendMessageQueued();
        };

        // Add listener for buffer drained
        this._channel.addEventListener('bufferedamountlow', listener);
        return;
      }

      try {
        // Actually sending data
        super.send(message);
        message = this.webRTCMessageQueue.shift();
      } catch (error) {
        throw new Error(`Error send message, reason: ${error.name} - ${error.message}`);
      }
    }
  }

  send(chunk) {
    this.webRTCMessageQueue.push(chunk);

    if (this.webRTCPaused) {
      // WebRTC paused, waiting for buffer to drain
      return;
    }

    this.sendMessageQueued();
  }
}

export default Peer;
