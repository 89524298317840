import { action } from 'easy-peasy';

const dataModel = {
  files: {},

  /**
   * Add chunk to file (or create a new file if it does not exist)
   *
   * @param {object} state    The part of the state tree this action is against
   * @param {object} payload  An object containing the uuid of the file and the
   *                          chunk to add to it
   */
  addChunk: action((state, payload) => {
    if (!(payload.uuid in state.files)) {
      // File does not exist, add it now
      console.log(`File ${payload.uuid} does not exist, initializing it`);

      // state.files[payload.uuid] = {
      //   uuid: payload.uuid,
      //   content: payload.chunk,
      //   state: 'pending',
      // };

      state.files = {
        ...state.files,
        [payload.uuid]: {
          uuid: payload.uuid,
          content: payload.chunk,
          state: 'pending',
        },
      };

      console.log('Initialized file', {
        uuid: payload.uuid,
        content: payload.chunk,
        state: 'pending',
      });
    } else {
      // Add chunk to existing file
      console.log(`Adding chunk to ${payload.uuid}`);
      
      // state.files[payload.uuid].content += payload.chunk;

      state.files = {
        ...state.files,
        [payload.uuid]: {
          ...state.files[payload.uuid],
          content: `${state.files[payload.uuid].content}${payload.chunk}`,
        },
      };

      console.log(`Added chunk '${payload.chunk.substr(0, 6)}…'`);
      console.log(`Filesize so far ${state.files[payload.uuid].content.length}`);
    }
  }),

  /**
   * Set the state of a file to 'complete' and add its filename
   *
   * @param {object} state    The part of the state tree this action is against
   * @param {object} payload  An object containing the uuid of the file, its
   *                          filename and MIME type
   */
  completeFile: action((state, payload) => {
    if (!(payload.uuid in state.files)) {
      return;
    }

    state.files[payload.uuid].name = payload.filename;
    state.files[payload.uuid].type = payload.filetype;
    state.files[payload.uuid].state = 'complete';
  }),
};

export default dataModel;
