import React, { useState, useEffect, useCallback, useRef} from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useSocket } from 'use-socketio';

import firebase from 'firebase/app';
import 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth';

import Panel from 'components/common/Panel';

import Message from './Message';
import File from './File';
import Whiteboard from './Whiteboard';
import FileInput from './FileInput';

import Sounds from 'common/Sounds';

import { ReactComponent as SendIcon } from 'images/send.svg';
import { ReactComponent as ArrowIcon } from 'images/arrow.svg';

const useStyles = createUseStyles({
  subPanel: {
    width: '348px',
    height: '100%',

    position: 'absolute',
    top: 0,

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',

    padding: '24px',
    boxSizing: 'border-box',
  },
  whiteboardArea: {
    right: '348px',
    borderRight: '1px solid #e4e4e4',
  },
  messagesArea: {
    paddingTop: 0,
    right: 0,
  },
  arrow: {
    position: 'absolute',
    left: '6px',
    top: '50%',
    transform: ({ expanded }) => expanded ? 'translateY(-50%)' : 'translateY(-50%) rotate(180deg)',
    width: '16px',
    fill: ({ theme }) => theme.colorSecondaryPale,
    transition: 'transform 200ms ease-in',
    cursor: 'pointer',
  },
  messagesWrapper: {
    overflowY: 'auto',
    width: '100%',
    flex: 1,
  },
  messagesContainer: {
    width: '100%',
    flex: 1,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    minHeight: '100%',
  },
  chatOverlay: {
    background: 'linear-gradient(0deg, rgba(255,255,255,1) 10%, rgba(255,255,255,0) 90%)',
    position: 'absolute',
    bottom: '95px',
    left: 0,
    width: '100%',
    height: '12px',
  },
  compose: {
    marginTop: '16px',
    width: '100%',
    display: 'flex',
  },
  textarea: {
    outline: 'none',
    border: '2px solid #e0dede',
    borderRadius: '12px',
    transition: '400ms all',
    padding: '10px',
    width: '68%',
    '&:hover': {
      border: '2px solid #6eaeff',
      transition: '100ms all',
    },
  },
  sendButton: {
    width: '50px',
    height: '50px',
    borderRadius: '40px',
    padding: '12px',
    border: 0,
    color: '#fff',
    backgroundColor: ({ hue }) => `hsl(${hue}, 70%, 50%)`,
    transition: '400ms all',
    margin: '0 10px',
    '&:hover': {
      color: '#fff',
      backgroundColor: ({ hue }) => `hsl(${hue}, 70%, 70%)`,
      boxShadow: ({ hue }) => `0 4px 12px -6px hsl(${hue}, 70%, 80%)`,
      transform: 'translateY(-3px)',
      transition: '100ms all',
      cursor: 'pointer',
    },
    '&:active': {
      color: '#fff',
      backgroundColor: '#e0dede',
      boxShadow: '0 0 0 0 #03a9f4',
      transform: 'translateY(0)',
      transition: '100ms all',
    },
  },
});

const auth = firebase.auth();

function Chat() {
  const [expanded, setExpanded] = useState(false);
  const [message, setMessage] = useState('');

  const [user] = useAuthState(auth);
  const profile = useStoreState((state) => state.user);

  const theme = useTheme();
  const classes = useStyles({ expanded, theme, hue: profile.color });

  const messages = useStoreState((state) => state.chat.messages);
  const addTextMessage = useStoreActions((actions) => actions.chat.addTextMessage);

  const messagesWrapper = useRef();

  const displayName = user ? user.displayName : profile.username;

  const { socket } = useSocket('CHAT_MESSAGE', (payload) => {
    addTextMessage(payload);

    if (displayName !== payload.username) {
      Sounds.notification.play();
    }
  });

  const updateMessage = (event) => {
    setMessage(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      send();
    }
  };

  const showOnboarding = () => {
    const onboardingMessages = [
      'Welcome to Chillmate! 👋',
      'This is the chat area. 💬',
      'You can chat with other people in the room.',
      'You can also upload files and share your screen.',
      'or you can just chill and draw on the whiteboard. 🎨',
      '🔒 Video streams and file transfers are peer-to-peer and encrypted.',
      'Have fun! 🎉',
    ];

    const onboardingHue = Math.random() * 360;

    onboardingMessages.forEach((message, index) => {
      setTimeout(() => {
        addTextMessage({
          username: 'Chillmate',
          photoURL: 'https://chillmate.app/logo192.png',
          message,
          color: onboardingHue,
        });

        if (index === 0) {
          Sounds.notification.play();
        }
      }, (index + 1) * 1800);
    });
  };

  const send = useCallback(() => {
    socket.emit('SEND_CHAT_MESSAGE', {
      username: displayName,
      photoURL: user ? user.photoURL : null,
      message,
      color: profile.color,
    });

    setMessage('');
  }, [message]);

  useEffect(() => {
    messagesWrapper.current.scrollTop = messagesWrapper.current.scrollHeight;
  }, [messages]);

  useEffect(() => {
    const hasSeenOnboarding = localStorage.getItem('hasSeenOnboarding');

    if (!hasSeenOnboarding) {
      localStorage.setItem('hasSeenOnboarding', true);
      showOnboarding();
    }
  }, []);

  const renderMessages = () => {
    return (
      <div className={classes.messagesWrapper} ref={messagesWrapper}>
        <div className={classes.messagesContainer}>
          {messages.map((item, index) => {
            const nextMessage = messages[index + 1];
            const showUsername = !(nextMessage && nextMessage.username === item.username);

            switch (item.type) {
              case 'text':
                return (
                  <Message
                    key={index}
                    username={item.username}
                    showUsername={showUsername}
                    message={item.message}
                    photoURL={item.photoURL}
                    color={item.color}
                    isMine={item.username === displayName}
                    timestamp={item.timestamp}
                  />
                );
              case 'file':
                return (
                  <File
                    key={index}
                    item={item}
                    username={item.username}
                    showUsername={showUsername}
                    photoURL={item.photoURL}
                    color={item.color}
                    isMine={item.username === displayName}
                  />
                );
              default:
                return <></>;
            }
          })}
        </div>
        <div className={classes.chatOverlay}></div>
      </div>
    );
  };

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Panel
      width={expanded ? '700px' : '348px'}
      height="calc(100% - 48px)"
      overflow="hidden"
    >
      <FileInput>
        <div className={`${classes.subPanel} ${classes.whiteboardArea}`}>
          <Whiteboard />
        </div>
        <div className={`${classes.subPanel} ${classes.messagesArea}`}>
          {renderMessages()}

          <div className={classes.compose}>
            <textarea
              className={classes.textarea}
              placeholder={"Aa"}
              onChange={updateMessage}
              onKeyDown={handleKeyDown}
              value={message}
            />
            <button type="button" onClick={send} className={classes.sendButton}>
              <SendIcon />
            </button>
          </div>
        </div>
        <ArrowIcon
          className={classes.arrow}
          onClick={toggleExpand}
        />
      </FileInput>
    </Panel>
  );
}

export default Chat;
