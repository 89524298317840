import Jungle from './Jungle';

let wetGain = null;

const getMultiplier = (x) => {
  if (x < 0) { 
    return x / 12;
  } else {
    const a5 = 1.8149080040913423e-7;
    const a4 = -0.000019413043101157434;
    const a3 = 0.0009795096626987743;
    const a2 = -0.014147877819596033;
    const a1 = 0.23005591195033048;
    const a0 = 0.02278153473118749;

    const x1 = x;
    const x2 = x * x;
    const x3 = x * x * x;
    const x4 = x * x * x * x;
    const x5 = x * x * x * x * x;

    return a0 + x1 * a1 + x2 * a2 + x3 * a3 + x4 * a4 + x5 * a5;
  }
}

const createPitchShifter = (audioContext) => {
  const effect = new Jungle(audioContext);
  effect.output.connect(wetGain);
  effect.setPitchOffset(getMultiplier(0));
  window.effect = effect;
  return effect;
};

const convertToMono = (audioContext, input) => {
  const splitter = audioContext.createChannelSplitter(2);
  const merger = audioContext.createChannelMerger(2);

  input.connect(splitter);
  splitter.connect(merger, 0, 0);
  splitter.connect(merger, 0, 1);

  return merger;
}

export default (stream) => {
  const audioContext = new (window.AudioContext || window.webkitAudioContext)();
  const input = audioContext.createMediaStreamSource(stream);
  let audioInput = convertToMono(audioContext, input);

  const outputMix = audioContext.createGain();
  wetGain = audioContext.createGain();
  wetGain.connect(outputMix);
  outputMix.connect(audioContext.destination);

  const effectNode = createPitchShifter(audioContext);
  audioInput.connect(effectNode.input);

  return {
    setPitch: (pitchOffset) => {
      effectNode.setPitchOffset(getMultiplier(pitchOffset));
    },
    setVolume: (volume) => {
      outputMix.gain.value = volume;
    },
  };
};
