import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    padding: '6px 10px',
    backgroundColor: '#fff',
    borderRadius: '99px', 
  },
  label: {
    fontSize: '0.7rem',
    color: ({ color }) => color,
    fontWeight: '600',
    letterSpacing: '1px',
    whiteSpace: 'nowrap',
  },
  icon: {
    width: '15px',
    marginRight: '5px',
    lineHeight: 0,
  },
});

function Badge({ color, label, icon, className }) {
  const theme = useTheme();
  const classes = useStyles({ theme, color });

  return (
    <div className={`${classes.container} ${className}`}>
      <div className={classes.icon}>{icon}</div>
      <span className={classes.label}>{label}</span>
    </div>
  );
}

export default Badge;
