import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import { Adsense } from '@ctrl/react-adsense';

const useStyles = createUseStyles({
  container: {
    width: '420px',
    backgroundColor: '#fff',
    paddingTop: '100px',
    paddingBottom: '60px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
});

function Ads({ style = {} }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.container}>
      <Adsense
        client="ca-pub-1796780475981437"
        slot="5570423627"
        format="auto"
        responsive="true"
        style={{ display: 'block' }}
      />
    </div>
  );
}

export default Ads;
