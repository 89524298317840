import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import Panel from 'components/common/Panel';
import Emoji from 'components/common/Emoji';
import Login from 'components/common/Login';
import InputGroup from 'components/common/InputGroup';
import VideoBubble from 'components/common/VideoBubble';
import Legal from 'components/common/Legal';

import { ReactComponent as Logo } from 'images/logo.svg';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: '80%',
    maxWidth: '340px',
  },
});

function Menu({ history }) {
  const theme = useTheme();
  const classes = useStyles({ theme, hue: Math.random() * 360 });

  /**
   * Navigate to route /room/:roomId
   * 
   * @param {string} roomId   The id of the room to navigate to
   */
  const createRoom = (roomId) => {
    history.push(`/room/${roomId}`);
  };

  return (
    <div className={classes.container}>
      <Logo className={classes.logo} />

      <Panel width="400px">
        <p>
          <Emoji symbol="👋" label="hello" /> Enter a code to join (or start) a video call
        </p>

        <InputGroup
          label="Join"
          placeholder="Type the group code"
          onSubmit={createRoom}
        />
        
        <Login />

      </Panel>

      <VideoBubble
        filename="videos/bubble_1.mp4"
        animated={true}
        x={-640}
        y={-440}
      />

      <VideoBubble
        filename="videos/bubble_2.mp4"
        x={-360}
        y={-640}
        scale={1.15}
        style={{ animationDelay: '600ms' }}
      />

      <VideoBubble
        filename="videos/bubble_3.mp4"
        x={800}
        y={400}
        scale={1.4}
        style={{ animationDelay: '400ms' }}
      />

      <Legal />
    </div>
  )
}

export default Menu;
