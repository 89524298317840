import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import Linkify from 'linkifyjs/react';
import emojiRegex from 'emoji-regex';

const useStyles = createUseStyles({
  message: {
    color: ({ hue, codeOnly }) => codeOnly ? `hsl(${hue}, 70%, 35%)` : '#fff',
    fontFamily: ({ codeOnly }) => codeOnly ? `'Ubuntu Mono', monospace` : `'Exo 2', sans-serif`,
    borderRadius: ({ emojiOnly }) => emojiOnly ? '50px' : '20px',
    padding: '10px 17px',
    margin: ({ showUsername }) => showUsername ? '8px 0px 34px 0px' : '8px 0px 4px 0px',
    position: 'relative',
    width: 'fit-content',
    maxWidth: 'calc(100% - 34px)',
    wordWrap: 'anywhere',
    border: ({ hue, codeOnly }) => codeOnly ? `2px solid hsl(${hue}, 70%, 50%)` : 'none',
    boxShadow: ({ hue, emojiOnly, codeOnly }) => emojiOnly || codeOnly ? 'none' : `0 5px 6px -3px hsla(${hue}, 90%, 54%, 0.34)`,
    backgroundColor: ({ hue, emojiOnly, codeOnly }) => codeOnly ? `hsl(${hue} 70% 50% / 10%)` : emojiOnly ? `hsl(${hue} 0% 95% / 100%)` : `hsl(${hue}, 70%, 50%)`,
    alignSelf: ({ isMine }) => isMine ? 'flex-end' : 'flex-start',
    fontSize: ({ emojiOnly }) => emojiOnly ? '3.2em' : '1em',
  },
  userDetails: {
    position: 'absolute',
    bottom: ({ hasPhoto }) => hasPhoto ? '-28px' : '-22px',
    left: ({ isMine }) => isMine ? 'auto' : '10px',
    right: ({ isMine }) => isMine ? '10px' : 'auto',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '14px',
    padding: '2px 2px 2px 4px',
    backgroundColor: ({ hue }) => `hsl(${hue}, 70%, 95%)`,
  },
  username: {
    fontWeight: 500,
    fontFamily: `'Exo 2', sans-serif`,
    fontSize: '11px',
    color: ({ hue }) => `hsl(${hue}, 70%, 50%)`,
    whiteSpace: 'nowrap',
    order: ({ isMine }) => isMine ? 0 : 1,
    margin: '0 4px',
  },
  photo: {
    width: '20px',
    borderRadius: '100%',
  },
  link: {
    color: ({ hue }) => `hsl(${hue}, 100%, 80%)`,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: '#fff',
      textDecoration: 'underline',
    },
  },
});

function Message({ username, photoURL, showUsername, message, color, isMine }) {
  const emojiOnly = message.length > 0 && message.replace('⚡️', '').replace(emojiRegex(), '').length === 0;
  const codeOnly = message.startsWith('```') && message.endsWith('```');
  
  const hasPhoto = photoURL !== null;

  const theme = useTheme();
  const classes = useStyles({ hue: color, isMine, showUsername, hasPhoto, emojiOnly, codeOnly, theme });

  return (
    <div className={classes.message}>
      {showUsername
      ? (
        <div className={classes.userDetails}>
          <span className={classes.username}>{username}</span>
          {photoURL
          ? (
            <img src={photoURL} className={classes.photo} />
          )
          : null}
        </div>
      ) : null}

      <Linkify
        options={{
          attributes: { rel: 'nofollow' },
          className: classes.link,
          defaultProtocol: 'https',
          ignoreTags: ['style', 'script'],
          nl2br: true,
        }}
      >
        {codeOnly ? message.substring(3, message.length - 3) : message}
      </Linkify>
    </div>
  );
}

export default Message;
