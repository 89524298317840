import React from 'react';
import { ThemeProvider } from 'react-jss';
import { SocketIOProvider } from 'use-socketio';
import { StoreProvider } from 'easy-peasy';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { P2PProvider } from './P2P';

import store from './store';

import Wrapper from './components/common/Wrapper';

import Menu from './routes/Menu';
import Room from './routes/Room';

import Privacy from './routes/Privacy';
import Cookies from './routes/Cookies';
import Terms from './routes/Terms';

const theme = {
  colorPrimary: 'hsl(214, 100%, 50%)',  // #0070ff
  colorSecondary: 'hsl(0, 0%, 60%)',    // #999
  colorDanger: 'hsl(342, 85%, 52%)',    // #ed1e5c

  colorPrimaryPale: 'hsla(214, 100%, 50%, 0.7)',
  colorSecondaryPale: 'hsla(0, 0%, 60%, 0.7)',
  colorDangerPale: 'hsla(342, 85%, 52%, 0.7)',

  colorLinkPrimary: '#8e8e8e',
  colorLinkPrimaryHover: '#5e5e5e',

  shadowPrimary: '0 4px 12px -6px rgba(0, 0, 0, 0.2)',
  brightVideo: 'contrast(110%) brightness(110%) saturate(120%)',
};

function App() {
  return (
    <StoreProvider store={store}>
      <SocketIOProvider
        url={process.env.REACT_APP_WEBSOCKET_URL}
        opts={{ transports: ['websocket'] }}
      >
        <P2PProvider>
          <ThemeProvider theme={theme}>
            <Wrapper>
              <BrowserRouter>
                <Switch>
                  <Route path="/" exact component={Menu} />
                  <Route path="/room/:roomId" component={Room} />
                  <Route path="/privacy" component={Privacy} />
                  <Route path="/cookies" component={Cookies} />
                  <Route path="/terms" component={Terms} />
                </Switch>
              </BrowserRouter>
            </Wrapper>
          </ThemeProvider>
        </P2PProvider>
      </SocketIOProvider>
    </StoreProvider>
  );
}

export default App;
