import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    height: ({ thickness }) => `${thickness}px`,
  },
  progress: {
    width: ({ percent }) => `${percent}%`,
    height: ({ thickness }) => `${thickness}px`,
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: ({ color }) => color,
  },
});

function ProgressBar({ color, thickness, percent, className }) {
  const theme = useTheme();
  const classes = useStyles({ theme, thickness, percent, color });

  return (
    <div className={`${classes.container} ${className}`}>
      <div className={classes.progress}></div>
    </div>
  );
}

export default ProgressBar;
