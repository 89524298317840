/**
 * Asynchronously execute the provided function once for each array element
 *
 * @param {array} arr     The array to call the function for each of its elements
 * @param {function} cb   The callback function to call
 * @return {Promise}
 */
export const asyncForEach = async (arr, cb) => {
  for (let i = 0; i < arr.length; i += 1) {
    // eslint-disable-next-line no-await-in-loop
    await cb(arr[i], i, arr);
  }
};

export const convertSizeToHumanReadableFormat = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (bytes === 0) {
    return '0 Byte';
  }

  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
};
