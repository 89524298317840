import React, { useState, useEffect, useRef } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useStoreActions } from 'easy-peasy';
import { useSocket } from 'use-socketio';
import hark from 'hark';

import VoiceChanger from 'voice-changer';

import ControlButton from './ControlButton';

import { ReactComponent as ScreenIcon } from 'images/icons/screen.svg';
import { ReactComponent as CameraIcon } from 'images/icons/camera.svg';
import { ReactComponent as PitchIcon } from 'images/icons/pitch.svg';
import { ReactComponent as MicIcon } from 'images/icons/mic.svg';

const useStyles = createUseStyles({
  container: {
    position: 'relative',
  },
  videoContainer: {
    width: ({ isInCall }) => isInCall ? '200px' : '400px',
    height: ({ isInCall }) => isInCall ? '200px' : '400px',
    margin: '20px',
    borderRadius: '100%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    transition: 'width 200ms ease-in, height 200ms ease-in',
    boxShadow: ({ isSpeaking, volume, theme }) => isSpeaking
      ? `${theme.shadowPrimary}, 0 0 0 ${(0.34 * (volume + 50)) + 3}px ${theme.colorPrimary}`
      : theme.shadowPrimary,
    transform: 'translateZ(0)',
  },
  video: {
    height: '100%',
    transform: ({ sharingScreen }) => sharingScreen ? 'none' : 'scaleX(-1)',
    filter: ({ theme, sharingScreen }) => sharingScreen ? 'none' : theme.brightVideo,
  },
  screenButton: {
    position: 'absolute',
    bottom: '50%',
    left: '-4%',
    transform: 'translateY(50%)',
  },
  cameraButton: {
    position: 'absolute',
    bottom: '8%',
    left: '8%',
  },
  pitchButton: {
    position: 'absolute',
    bottom: '1%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  muteButton: {
    position: 'absolute',
    bottom: '8%',
    right: '8%',
  },
});

function UserVideo({ isInCall, switchVideoTrack }) {
  const [isSpeaking, setSpeaking] = useState(false);
  const [volume, setVolume] = useState(-100);

  const [audioTracks, setAudioTracks] = useState(null);
  const [videoTracks, setVideoTracks] = useState(null);
  
  const [sharingScreen, setSharingScreen] = useState(false);

  const [pitch, setPitch] = useState(50);

  const theme = useTheme();
  const classes = useStyles({ isInCall, isSpeaking, sharingScreen, volume, theme });

  const videoRef = useRef();
  const pitchControlRef = useRef();
  const voiceControlRef = useRef();

  const setStream = useStoreActions((actions) => actions.user.setStream);

  const { socket } = useSocket();

  const getMediaStream = async (constraints) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      return stream;
    } catch (error) {
      console.error(`Couldn't access the camera and/or microphone (${error.message})`);

      /* If we couldn't access the camera and/or microphone (either the user
       * does not have them connected, or denied permission to access them),
       * create an empty `MediaStream` to handle it */
      return new MediaStream();
    }
  };

  useEffect(() => {
    (async () => {
      const stream = await getMediaStream({ video: true, audio: true });
      setStream(stream);

      setVideoTracks(stream.getVideoTracks());
      setAudioTracks(stream.getAudioTracks());
      
      videoRef.current.srcObject = stream;

      const voiceControl = VoiceChanger(stream);
      voiceControl.setVolume(0);
      voiceControlRef.current = voiceControl;

      const speechEvents = hark(stream);
      speechEvents.on('speaking', () => setSpeaking(true));
      speechEvents.on('stopped_speaking', () => setSpeaking(false));
      speechEvents.on('volume_change', (currentVolume, threshold) => {
        setVolume(currentVolume);
      });
    })();
  }, []);

  useEffect(() => {
    if (!voiceControlRef.current) {
      return;
    }

    console.log(`User pitch set to ${pitch}%`);
    voiceControlRef.current.setPitch((pitch - 50) / 5);
  }, [pitch, voiceControlRef]);

  const toggleScreenSharing = async (enabled) => {
    let stream;

    if (enabled) {
      stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
    } else {
      stream = await getMediaStream({ video: true });
    }

    setSharingScreen(enabled);

    socket.emit('SET_STREAM_VIDEO', {
      track: (enabled) ? 'screen' : 'camera',
    });

    const videoTracks = stream.getVideoTracks();
    setVideoTracks(videoTracks);
    switchVideoTrack(videoTracks[0]);

    videoRef.current.srcObject.removeTrack(videoRef.current.srcObject.getVideoTracks()[0]);
    videoRef.current.srcObject.addTrack(videoTracks[0]);
  };
  
  return (
    <div className={classes.container}>
      <div className={classes.videoContainer}>
        <video
          className={classes.video}
          muted
          autoPlay
          playsInline
          ref={videoRef}
        />
      </div>

      {isInCall ? (
        <>
          <ControlButton
            icon={<ScreenIcon />}
            className={classes.screenButton}
            initialStatus={false}
            onToggle={toggleScreenSharing}
          />
          <ControlButton
            icon={<CameraIcon />}
            className={classes.cameraButton}
            onToggle={(enabled) => {
              if (videoTracks) {
                videoTracks[0].enabled = enabled;
              }
            }}
          />
          <ControlButton
            icon={<PitchIcon />}
            className={classes.pitchButton}
            onSlide={(percentage) => {
              // 0% lowest frequency, 50% normal voice, 100% highest frequency
              console.log(`Setting our pitch to ${percentage} for all participants`);
              setPitch(percentage);
              socket.emit('SET_PITCH', { pitch: percentage });
            }}
            onClick={() => {
              console.log(`Resetting pitch`);
              if (pitchControlRef) {
                setPitch(50);
                pitchControlRef.current.setPercentage(50);
              }
            }}
            onSliderShow={() => {
              if (voiceControlRef.current) {
                voiceControlRef.current.setVolume(1);
              }
            }}
            onSliderHide={() => {
              if (voiceControlRef.current) {
                voiceControlRef.current.setVolume(0);
              }
            }}
            ref={pitchControlRef}
          />
          <ControlButton
            icon={<MicIcon />}
            className={classes.muteButton}
            onToggle={(enabled) => {
              if (audioTracks) {
                audioTracks[0].enabled = enabled;
              }
            }}
          />
        </>
      ) : null}
    </div>
  );
}

export default UserVideo;
