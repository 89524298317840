import { ReactComponent as FileIcon } from 'images/icons/types/file.svg';
import { ReactComponent as ImageIcon } from 'images/icons/types/image.svg';
import { ReactComponent as VideoIcon } from 'images/icons/types/video.svg';
import { ReactComponent as ZipIcon } from 'images/icons/types/zip.svg';
import { ReactComponent as VectorIcon } from 'images/icons/types/vector.svg';
import { ReactComponent as AudioIcon } from 'images/icons/types/audio.svg';
import { ReactComponent as CodeIcon } from 'images/icons/types/code.svg';
import { ReactComponent as FontIcon } from 'images/icons/types/font.svg';
import { ReactComponent as PdfIcon } from 'images/icons/types/pdf.svg';



const fileTypeMapping = {
    file: {
        icon: FileIcon,
        color: '#0092ff',
        label: 'File',
    },
    video: {
        icon: VideoIcon,
        color: '#725dbe',
        label: 'Video',
    },
    image: {
        icon: ImageIcon,
        color: '#ff9b26',
        label: 'Image',
    },
    zip: {
        icon: ZipIcon,
        color: '#999999',
        label: 'ZIP',
    },
    vector: {
        icon: VectorIcon,
        color: '#ff9b26',
        label: 'Vector',
    },
    audio: {
        icon: AudioIcon,
        color: '#0092ff',
        label: 'Audio',
    },
    code: {
        icon: CodeIcon,
        color: '#00d66b',
        label: 'Code',
    },
    font: {
        icon: FontIcon,
        color: '#0092ff',
        label: 'Font',
    },
    pdf: {
        icon: PdfIcon,
        color: '#d4315f',
        label: 'PDF',
    },
};

const mimeTypeMapping = {
    'image/jpeg': {
        ...fileTypeMapping.image,
        label: 'JPEG',
    },
    'image/pjpeg': {
        ...fileTypeMapping.image,
        label: 'JPEG',
    },
    'image/png': {
        ...fileTypeMapping.image,
        label: 'PNG',
    },
    'image/gif': {
        ...fileTypeMapping.image,
        label: 'GIF',
    },
    'video/quicktime': {
        ...fileTypeMapping.video,
        label: 'MOV',
    },
    'video/mp4': {
        ...fileTypeMapping.video,
        label: 'MP4',
    },
    'application/zip': fileTypeMapping.zip,
    'application/x-compressed': fileTypeMapping.zip,
    'application/x-zip-compressed': fileTypeMapping.zip,
    'multipart/x-zip': fileTypeMapping.zip,
    'image/svg+xml': {
        ...fileTypeMapping.vector,
        label: 'SVG',
    },
    'audio/mpeg': {
        ...fileTypeMapping.audio,
        label: 'MP3',
    },
    'audio/x-wav': {
        ...fileTypeMapping.audio,
        label: 'WAV',
    },
    'text/javascript': {
        ...fileTypeMapping.code,
        label: 'JS',
    },
    'application/json': {
        ...fileTypeMapping.code,
        label: 'JSON',
    },
    'font/ttf': {
        ...fileTypeMapping.font,
        label: 'TTF',
    },
    'application/pdf': fileTypeMapping.pdf,
};

export const mapMimeType = (mimeType) => {
    if (!(mimeType in mimeTypeMapping)) {
        return fileTypeMapping.file;
    }
    return mimeTypeMapping[mimeType];
};
