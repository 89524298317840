import { action, computed } from 'easy-peasy';

const chatModel = {
  messages: [],

  /**
   * Add a text message
   *
   * @param {object} state                  State of the chat model
   * @param {object} payload                Payload of the action
   * @param {string} payload.username       Username of the user that sent the message
   * @param {string} payload.photoURL       URL of the photo of the user that sent the message
   * @param {string} payload.color          Color of the user that sent the message
   * @param {string} payload.message        Content of the text message
   */
  addTextMessage: action((state, payload) => {
    state.messages.push({
      type: 'text',
      username: payload.username,
      photoURL: payload.photoURL,
      color: payload.color,
      message: payload.message,
      timestamp: new Date(),
    });
  }),

  /**
   * Add new file
   *
   * @param {object} state                  State of the chat model
   * @param {object} payload                Payload of the action
   * @param {string} payload.username       Username of the user that sent the message
   * @param {string} payload.photoURL       URL of the photo of the user that sent the message
   * @param {string} payload.color          Color of the user that sent the message
   * @param {string} payload.uuid           Unique identifier for this file
   * @param {boolean} payload.isSender      Whether we are the sender of this file
   * @param {number} payload.totalChunks    Total number of the chunks that will be received for this file
   * @param {string} payload.filename       Name of the file, including the file extension
   * @param {string} payload.filetype       Type of the file (audio, image etc)
   * @param {string} payload.filesize       Size of the file (in bytes)
   */
  addFile: action((state, payload) => {
    state.messages.push({
      type: 'file',
      username: payload.username,
      photoURL: payload.photoURL,
      color: payload.color,
      uuid: payload.uuid,
      content: 'content' in payload ? payload.content : '',
      receivedChunks: 0,
      sentChunks: 0,
      isSender: 'isSender' in payload && payload.isSender,
      totalChunks: payload.totalChunks,
      filename: payload.filename,
      filetype: payload.filetype,
      filesize: payload.filesize,
      status: 'pending',
      timestamp: new Date(),
    });
  }),

  /**
   * Add chunk to existing file
   *
   * @param {object} state                  State of the chat model
   * @param {object} payload                Payload of the action
   * @param {string} payload.uuid           Unique identifier for this file
   * @param {string} payload.chunk          Content of this chunk
   */
  addFileChunk: action((state, payload) => {
    const index = state.messages.findIndex(({ type, uuid }) => type === 'file' && uuid === payload.uuid);
    const message = state.messages[index];
    state.messages[index] = {
      ...message,
      receivedChunks: message.receivedChunks + 1,
      content: message.content + payload.chunk,
      status: ('complete' in payload && payload.complete) ? 'complete' : 'pending',
    };
  }),

  /**
   * Increment the number of chunks sent
   *
   * @param {object} state                  State of the chat model
   * @param {object} payload                Payload of the action
   * @param {string} payload.uuid           Unique identifier for this file
   * @param {string} payload.sentChunks     Number of chunks sent
   */
  incrementSentChunks: action((state, payload) => {
    const index = state.messages.findIndex(({ type, uuid }) => type === 'file' && uuid === payload.uuid);
    const message = state.messages[index];
    state.messages[index] = {
      ...message,
      sentChunks: payload.sentChunks,
      status: ('complete' in payload && payload.complete) ? 'complete' : 'pending',
    };
  }),
};

export default chatModel;
