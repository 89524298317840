import React, { useEffect } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useSocket } from 'use-socketio';

import { useP2P } from 'P2P';

import VideoCall from 'components/VideoCall';
import Chat from 'components/Chat';

import Ads from 'components/common/Ads';
import Legal from 'components/common/Legal';

import Sounds from 'common/Sounds';

const useStyles = createUseStyles({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    minHeight: '100%',
    flexWrap: 'wrap',
  },
  roomId: {
    fontFamily: 'monospace',
    fontSize: '1.1rem',
    color: '#fff',
    backgroundColor: ({ theme }) => theme.colorPrimary,
    borderRadius: '9999px',
    padding: '14px 42px',
    margin: 0,
    position: 'fixed',
    top: '18px',
    left: '18px',
  },
});

function Room(props) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const roomId = props.match.params.roomId;

  const stream = useStoreState((state) => state.user.stream);
  const setUserId = useStoreActions((actions) => actions.user.setId);

  const { socket } = useSocket();

  const {
    peers,
    createPeer,
    addPeer,
    signalPeer,
    removePeer,
  } = useP2P();

  useSocket('CLIENTS_IN_ROOM', (clients) => {
    console.log('Connected to room');
    console.log(`Our socket id is ${socket.id}`);
    console.log(`There are already ${clients.length} client(s) in the room`);

    setUserId(socket.id);

    clients.forEach((clientId) => {
      console.log(`Initiating connection to ${clientId}`);
      createPeer(clientId, (offer) => {
        socket.emit('SEND_SIGNAL', {
          clientToSignal: clientId,
          callerId: socket.id,
          signal: offer,
        });
      }, stream);
    });

    Sounds.connected.play();
  });

  useSocket('CLIENT_JOINED', (payload) => {
    console.log('Client connected', payload);
    addPeer(payload.signal, payload.callerId, (answer) => {
      socket.emit('RETURN_SIGNAL', {
        signal: answer,
        callerId: payload.callerId,
      });
    }, stream);
  });

  useSocket('RECEIVING_RETURN_SIGNAL', (payload) => {
    console.log('Receiving return signal, signaling peer', payload);
    signalPeer(payload.id, payload.signal);
  });

  useSocket('CLIENT_LEFT', (payload) => {
    console.log('Client disconnected', payload);
    removePeer(payload.id);

    Sounds.disconnected.play();
  });

  useEffect(() => {
    (async () => {
      if (stream !== null) {
        socket.emit('JOIN_ROOM', roomId);
      }
    })();
  }, [stream]);

  /**
   * Replace the existing video track of the user stream with the given video track
   *
   * @param {MediaStreamTrack} videoTrack   The new video track
   */
  const switchVideoTrack = (videoTrack) => {
    Object.values(peers).forEach((peer) => {
      peer.replaceTrack(stream.getVideoTracks()[0], videoTrack, stream);
    });
  };

  return (
    <div className={classes.wrapper}>
      <p className={classes.roomId}>{roomId}</p>

      <VideoCall
        peers={peers}
        switchVideoTrack={switchVideoTrack}
      />

      <Chat />

      <Legal
        style={{
          position: 'fixed',
          bottom: '24px',
          left: '24px',
        }}
      />
    </div>
  );
}

export default Room;
