import React, { useEffect, useState, useRef } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import hark from 'hark';

import VoiceChanger from 'voice-changer';

import ControlButton from './ControlButton';

import { ReactComponent as VolumeIcon } from 'images/icons/volume.svg';

const useStyles = createUseStyles({
  container: {
    position: 'relative',
  },
  videoContainer: {
    width: ({ track }) => track === 'camera' ? '300px' : '480px',
    height: '300px',
    margin: 20,
    borderRadius: ({ track }) => track === 'camera' ? '100%' : '16px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0 4px 12px -6px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    boxSizing: 'border-box',
    boxShadow: ({ isSpeaking, volume, theme }) => isSpeaking
      ? `${theme.shadowPrimary}, 0 0 0 ${(0.34 * (volume + 50)) + 3}px ${theme.colorPrimary}`
      : theme.shadowPrimary,
    transform: 'translateZ(0)',
  },
  video: {
    height: '100%',
    filter: ({ theme, track }) => track === 'camera' ? theme.brightVideo : 'none',
  },
  volumeButton: {
    position: 'absolute',
    bottom: '8%',
    right: '8%',
  },
});

function PeerVideo({ peer, pitch, track }) {
  const [isSpeaking, setSpeaking] = useState(false);
  const [volume, setVolume] = useState(-100);

  const theme = useTheme();
  const classes = useStyles({ isSpeaking, track, volume, theme });
  const ref = useRef();

  const voiceControlRef = useRef();

  useEffect(() => {
    peer.on('stream', (stream) => {
      ref.current.srcObject = stream;
      voiceControlRef.current = VoiceChanger(stream);

      const speechEvents = hark(stream);
      speechEvents.on('speaking', () => setSpeaking(true));
      speechEvents.on('stopped_speaking', () => setSpeaking(false));
      speechEvents.on('volume_change', (currentVolume, threshold) => {
        setVolume(currentVolume);
      });
    });
  }, []);

  useEffect(() => {
    if (!voiceControlRef.current) {
      return;
    }

    console.log(`Peer pitch set to ${pitch}%`);
    voiceControlRef.current.setPitch((pitch - 50) / 5);
  }, [pitch]);

  return (
    <div
      className={classes.container}
      onDoubleClick={() => {
        ref.current.requestFullscreen();
      }}
    >
      <div className={classes.videoContainer}>
        <video
          className={classes.video}
          autoPlay
          playsInline
          muted
          ref={ref}
        />
      </div>

      {track === 'camera' ? (
        <ControlButton
          icon={<VolumeIcon />}
          className={classes.volumeButton}
          onSlide={(percentage) => {
            if (voiceControlRef.current) {
              console.log(`Setting peer volume to ${percentage}`);
              voiceControlRef.current.setVolume(percentage * 0.02);
            }
          }}
        />
      ) : null}
    </div>
  );
}

export default PeerVideo;
