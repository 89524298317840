import { action } from 'easy-peasy';

import FakeUsernames from 'common/FakeUsernames';

const userModel = {
  id: null,
  setId: action((state, payload) => {
    state.id = payload.id;
  }),

  username: FakeUsernames.getRandom(),
  setUsername: action((state, payload) => {
    state.username = payload;
  }),

  color: Math.floor(Math.random() * 360),
  setColor: action((state, payload) => {
    state.color = payload;
  }),

  stream: null,
  setStream: action((state, payload) => {
    state.stream = payload;
  }),
};

export default userModel;
