import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    borderRadius: '100%',
    overflow: 'hidden',
    boxShadow: ({ hue }) => `rgba(0, 0, 0, 0.4) 0px 7px 20px -6px, 0 0 0 6px hsl(${hue}, 70%, 50%)`,
    position: 'absolute',
    boxSizing: 'border-box',
  },
  video: {
    borderRadius: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
});

function VideoBubble({ filename, animated = false, x, y, scale = 1.0, style = {} }) {
  const theme = useTheme();
  const classes = useStyles({ theme, hue: Math.random() * 360 });

  return (
    <div
      className={`${classes.container} animated-bubble ${animated ? 'animated-speech' : ''}`}
      style={{
        ...style,
        width: `${200 * scale}px`,
        height: `${200 * scale}px`,
        marginTop: y,
        marginLeft: x,
      }}
    >
      <video className={classes.video} src={filename} autoPlay loop muted />
    </div>
  );
}

export default VideoBubble;
