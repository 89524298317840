import React, { useState } from 'react';

import Peer from './peer';
import { P2PContext } from './context';

export const P2PProvider = ({ children }) => {
  const [peers, setPeers] = useState({});

  /**
   * Create a new connection and send the Offer signal to other peers
   *
   * @param {string} peerId           A unique id to store this peer
   * @param {function} sendOffer      A function that retrives the Offer
   *                                  signal and sends it to other peers
   * @param {?MediaStream} stream     A stream to initialize the peer
   */
  const createPeer = (peerId, sendOffer, stream = null) => {
    const opts = {
      initiator: true,
      trickle: false,
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:global.stun.twilio.com:3478' },
          {
            urls: 'turn:turn.icyhippo.com:3478',
            username: 'chillmate',
            credential: 'KRgUR2ecsS6vFDqW4a7y8G9b',
          },
        ],
      },
    };

    if (stream) {
      opts.stream = stream;
    }
    
    const peer = new Peer(opts);
    peer.on('signal', sendOffer);
    setPeers((prev) => ({ ...prev, [peerId]: peer }));
  };

  /**
   * Connect to an existing connection and send back the Answer signal
   *
   * @param {string} incomingSignal   The incoming signal of the initiator
   * @param {string} peerId           A unique id to store this peer
   * @param {function} sendOffer      A function that retrieves the Answer
   *                                  signal and sends it back
   * @param {?MediaStream} stream     A stream to initialize the peer
   */
  const addPeer = (incomingSignal, peerId, sendAnswer, stream = null) => {
    const opts = {
      initiator: false,
      trickle: false,
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:global.stun.twilio.com:3478' },
          {
            urls: 'turn:turn.icyhippo.com:3478',
            username: 'chillmate',
            credential: 'KRgUR2ecsS6vFDqW4a7y8G9b',
          },
        ],
      },
    };

    if (stream) {
      opts.stream = stream;
    }

    const peer = new Peer(opts);
    peer.on('signal', sendAnswer);
    peer.signal(incomingSignal);
    setPeers((prev) => ({ ...prev, [peerId]: peer }));
  };

  /**
   * Send the given signal to the peer with the given id
   *
   * @param {string} peerId           The unique if of the peer to signal
   * @param {string} signal           The signal to send to the peer
   */
  const signalPeer = (peerId, signal) => {
    console.log('Sending signal to peer', signal, peerId);
    console.log('PEERS WHEN SIGNAL WAS SENT', peers);
    peers[peerId].signal(signal);
  };

  /**
   * Remove the peer with the given id
   *
   * @param {string} peerId           The unique if of the peer to remove
   */
  const removePeer = (peerId) => {
    setPeers((prev) => {
      const newPeers = { ...prev };
      delete newPeers[peerId];
      return newPeers;
    });
  };

  if (typeof window === 'undefined') {
    return <>{children}</>;
  }

  return (
    <P2PContext.Provider value={{
      peers,
      createPeer,
      addPeer,
      signalPeer,
      removePeer,
    }}>
      {children}
    </P2PContext.Provider>
  );
};
