import React, { useState, useCallback } from 'react';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  textField: {
    border: '2px solid #e4e4e4',
    padding: '7px',
    borderRadius: '10px 0 0 10px',
  },
  button: {
    color: '#000',
    backgroundColor: '#e4e4e4',
    textTransform: 'uppercase',
    border: '1px solid #e4e4e4',
    padding: '8px 14px',
    borderRadius: '0 10px 10px 0',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#000',
      borderColor: '#000',
      cursor: 'pointer',
    },
  },
});

function InputGroup({ label, placeholder, onSubmit }) {
  const classes = useStyles();

  const [text, setText] = useState('');

  const callback = useCallback(() => {
    onSubmit(text);
  }, [onSubmit, text]);

  const handleUpdate = (event) => {
    setText(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      callback();
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder={placeholder}
        className={classes.textField}
        onChange={handleUpdate}
        onKeyDown={handleKeyDown}
        value={text}
      />
      <button type="button" className={classes.button} onClick={callback}>
        {label}
      </button>
    </div>
  );
}

export default InputGroup;
