import React, { useState, useMemo } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useSocket } from 'use-socketio';

import UserVideo from './UserVideo';
import PeerVideo from './PeerVideo';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flex: 1,
  },
  peersWrapper: {
    width: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
});

function VideoCall({ peers, switchVideoTrack }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const isInCall = useMemo(() => Object.keys(peers).length > 0, [peers]);

  const [pitches, setPitches] = useState({});
  const [tracks, setTracks] = useState({});

  useSocket('SET_PITCH', (payload) => {
    setPitches((pitches) => ({
      ...pitches,
      [payload.id]: payload.pitch,
    }));
  });

  useSocket('SET_STREAM_VIDEO', (payload) => {
    setTracks((tracks) => ({
      ...tracks,
      [payload.id]: payload.track,
    }));
  })

  const renderPeers = () => {
    if (Object.keys(peers).length === 0) {
      return <p>Waiting for people to join…</p>
    }

    return (
      <div className={classes.peersWrapper}>
        {Object.keys(peers).map((key, index) => (
          <PeerVideo
            key={index}
            peer={peers[key]}
            pitch={key in pitches ? pitches[key] : 50}
            track={key in tracks ? tracks[key] : 'camera'}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <UserVideo
        isInCall={isInCall}
        switchVideoTrack={switchVideoTrack}
      />
      {renderPeers()}
    </div>
  );
}

export default VideoCall;
