import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    width: '100%',
    height: '100%',
    minHeight: '100%',
  },
});

function Wrapper({ children }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.container}>
      {children}
    </div>
  );
}

export default Wrapper;
