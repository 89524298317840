import { useContext, useEffect, useRef } from 'react';
import { P2PContext } from './context';

export const useP2P = (eventKey = null, callback = null) => {
  const ctx = useContext(P2PContext);
  const callbackRef = useRef(callback);

  callbackRef.current = callback;

  const peerHandlerRef = useRef(function() {
    if (callbackRef.current) {
      callbackRef.current.apply(this, arguments);
    }
  });

  const subscribe = () => {
    if (ctx.peers && eventKey) {
      Object.values(ctx.peers)
        .forEach((peer) => {
          peer.on(eventKey, peerHandlerRef.current);
        });
    }
  };

  const unsubscribe = () => {
    if (ctx.peers && eventKey) {
      Object.values(ctx.peers)
        .forEach((peer) => {
          peer.removeListener(eventKey, peerHandlerRef.current);
        });
    }
  };

  useEffect(() => {
    unsubscribe();
    subscribe();
    return unsubscribe;
  }, [ctx.peers, eventKey]);

  return { ...ctx, unsubscribe, subscribe };
};
