import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { v4 as uuidv4 } from 'uuid';
// import download from 'downloadjs';

import firebase from 'firebase/app';
import 'firebase/auth';

import { useAuthState } from 'react-firebase-hooks/auth';

import { useP2P } from 'P2P';

const auth = firebase.auth();

const useStyles = createUseStyles({
  dropZone: {
    // width: '100%',
    // height: '100px',
    // backgroundColor: '#000',
  },
});

function FileInput({ children }) {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [user] = useAuthState(auth);
  const profile = useStoreState((state) => state.user);

  const addFile = useStoreActions((actions) => actions.chat.addFile);
  const addFileChunk = useStoreActions((actions) => actions.chat.addFileChunk);
  const incrementSentChunks = useStoreActions((actions) => actions.chat.incrementSentChunks);

  const { peers } = useP2P('data', (data) => {
    // Receiving data from peer
    const payload = JSON.parse(data.toString());

    if (!('type' in payload)) {
      console.warn('Missing required property \'type\' on received payload');
      return;
    }

    switch (payload.type) {
      case 'file':
        addFile({
          ...payload,
          isSender: false,
        });
        break;
      case 'file-chunk':
        addFileChunk(payload);
        break;
      default:
        // TODO: Handle plain-text data (P2P chat messages?)
        console.warn(`Unrecognized type '${payload.type}' received`);
    }
  });

  /**
   * Send data through WebRTC (P2P) to all connected peers
   *
   * @param {object} data   The data to send
   */
  const sendData = (data) => {
    // TODO: Add a `sendToAll()` function in P2P hooks
    Object.values(peers).forEach((peer) => {
      peer.send(JSON.stringify(data));
    });
  };

  /**
   * Split the given file content to chunks of ~16 KB
   * 
   * @param {string} content    A string with a `data:` URL
   *                            representing the file's data
   * @return {string[]}         An array containing chunks
   *                            of ~16 KB each
   */
  const splitToChunks = (content) => content.match(/.{1,16000}/g);

  /**
   * Send the given file to all connected peers
   */
  const sendFile = (file) => {
    const reader = new FileReader();
    const uuid = uuidv4();

    reader.onload = async (e) => {
      const fileContent = e.target.result;
      const chunks = splitToChunks(fileContent);
      console.log(`File ${uuid} split into ${chunks.length} chunks`);

      const data = {
        type: 'file',
        username: user ? user.displayName : profile.username,
        photoURL: user ? user.photoURL : null,
        color: profile.color,
        uuid,
        totalChunks: chunks.length,
        filename: file.name,
        filetype: file.type,
        filesize: file.size,
      };

      sendData(data);

      addFile({
        ...data,
        content: fileContent,
        isSender: true,
      });

      chunks.forEach((chunk, index) => {
        console.log(`Adding chunk ${index + 1}/${chunks.length} to queue`);
        const isLastChunk = index === chunks.length - 1;

        sendData({
          type: 'file-chunk',
          complete: isLastChunk,
          chunk,
          uuid,
        });

        incrementSentChunks({
          uuid,
          sentChunks: index + 1,
          complete: isLastChunk,
        });
      });

    }

    console.log('Reading as a data URL representing the file content');
    reader.readAsDataURL(file);
  };

  // const handleFileChange = async (event) => {
  //   const [file] = event.target.files;
  //   console.log('Loading', file);
  //   sendFile(file);
  // };

  const handleDrop = (event) => {
    console.log('File(s) dropped');

    event.preventDefault();

    if (event.dataTransfer.items) {
      [...event.dataTransfer.items].forEach((item, i) => {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          sendFile(file);
        }
      });
    } else {
      [...event.dataTransfer.files].forEach((file, i) => {
        sendFile(file);
      });
    }
  };
  
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  return (
    // <input type="file" onChange={handleFileChange} />
    <div
      className={classes.dropZone}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      {children}
    </div>
  );
}

export default FileInput;
