import React, { useState, useRef, useMemo } from 'react';
import { createUseStyles, useTheme } from 'react-jss';

import ProgressBar from 'components/common/ProgressBar';
import Badge from 'components/common/Badge';

import { convertSizeToHumanReadableFormat } from 'common/Utils';
import { mapMimeType } from 'common/FileTypes';

const useStyles = createUseStyles({
  container: {
    color: '#fff',
    fontFamily: `'Exo 2', sans-serif`,
    borderRadius: '20px',
    margin: ({ showUsername }) => showUsername ? '8px 0px 34px 0px' : '8px 0px 4px 0px',
    position: 'relative',
    width: 'fit-content',
    maxWidth: 'calc(100% - 34px)',
    wordWrap: 'anywhere',
    boxShadow: ({ hue }) => `0 5px 12px -3px hsla(${hue}, 90%, 54%, 0.14)`,
    background: ({ hue, isMine, color }) => `linear-gradient(to ${isMine ? 'right' : 'left'}, ${color} 0%, hsl(${hue}, 70%, 50%) 100%)`,
    alignSelf: ({ isMine }) => isMine ? 'flex-end' : 'flex-start',
    overflow: 'hidden',
    minWidth: '270px',
  },
  details: {
    position: 'relative',
    display: 'flex',
    padding: '8px 16px 12px 16px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  progressBar: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  preview: {
    width: '100%',
    height: '200px',
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: '100%',
  },
  video: {
    height: '100%',
  },
  fileTypeContainer: {
    display: 'flex',
    alignItems: 'center',
    order: ({ isMine }) => isMine ? 1 : 2,
  },
  fileInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: ({ isMine }) => isMine ? 'flex-end' : 'flex-start',
    order: ({ isMine }) => isMine ? 2 : 1,
  },
  filename: {
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1.14em',
    fontWeight: '600',
  },
  filesize: {
    fontSize: '0.7em',
    fontWeight: '600',
    backgroundColor: '#ffffff2e',
    padding: '2px 8px',
    borderRadius: '99px',
    marginTop: '2px',
  },
});

function File({ item, username, showUsername, photoURL, color, isMine }) {
  const fileType = mapMimeType(item.filetype);
  const FileTypeIcon = fileType.icon;

  const theme = useTheme();
  const classes = useStyles({ theme, hue: color, color: fileType.color, showUsername, isMine });

  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(true);

  const filesize = useMemo(() => convertSizeToHumanReadableFormat(item.filesize), [item.filesize]);

  const getFilenameWithoutExtension = (filename) => {
    if (filename.includes('.')) {
      return filename.split('.').slice(0, -1).join('.');
    }
    return filename;
  };

  const filename = getFilenameWithoutExtension(item.filename);

  const currentChunk = item.isSender ? item.sentChunks : item.receivedChunks;
  const isComplete = currentChunk >= item.totalChunks;
  const percent = isComplete ? 100 : (100 * currentChunk) / item.totalChunks;
  
  const handleVideo = () => {
    if (playing) {
      videoRef.current.pause();
      setPlaying(false);
    } else {
      videoRef.current.play();
      setPlaying(true);
    }
  };

  const renderImage = () => {
    return (
      <img
        src={item.content}
        alt={item.filename}
        className={classes.image}
      />
    );
  };

  const renderVideo = () => {
    return (      
      <video 
        className={classes.video}
        ref={videoRef}
        onClick={handleVideo}
        autoPlay
        loop
      >
        <source src={item.content} type={item.filetype} />
      </video>
    ); 
  };

  const renderPreview = () => {
    if (item.filetype.startsWith('image')) {
      return renderImage();
    }
    
    if (item.filetype.startsWith('video')) {
      return renderVideo();
    }

    return <></>;
  };

  return (
    <div className={classes.container}> 
      <div className={classes.preview}>
        {isComplete ? renderPreview() : null}
      </div>
      <div className={classes.details}> 
        {!isComplete ? (
          <ProgressBar
            color="#fff"
            thickness={4}
            percent={percent}
            className={classes.progressBar}
          />
        ) : null}
        <Badge
          color={fileType.color}
          label={fileType.label}
          icon={<FileTypeIcon />}
          className={classes.fileTypeContainer}
        />
        <div className={classes.fileInfo}>
          <span className={classes.filename}>{filename}</span>
          <span className={classes.filesize}>{filesize}</span>
        </div>
      </div>
    </div>
  );

  return (
    <a href={item.content} target="_blank">{item.filename}</a>
  );
}

export default File;
